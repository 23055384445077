/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 models/dungeon1.glb --transform --simplify 
Files: models/dungeon1.glb [4.82MB] > /home/user/Projects/dungeonfest/dungeon1-transformed.glb [1.77MB] (63%)
*/

import React from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Dungeon(props) {
  const group = React.useRef()
  const { nodes, materials, animations } = useGLTF('/dungeon1-transformed.glb')
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        {/* <pointLight name="Point027" castShadow power={4} color={0xff8844} intensity={Math.PI / 4} decay={20} distance={100} position={[-5, 2, 0.04]} rotation={[-Math.PI / 2, 0, 0]} /> */}
        <mesh name="Maze" receiveShadow geometry={nodes.Maze.geometry} material={materials.WallMaterial2} position={[3, 1, -11]} rotation={[0, 1.571, 0]} scale={2.744} />
        <mesh name="Suzanne" castShadow receiveShadow geometry={nodes.Suzanne.geometry} material={materials['Material.001']} position={[6.896, 1.945, 0.04]} rotation={[0, -1.571, 0]} />
        <mesh name="Floor" castShadow receiveShadow geometry={nodes.Floor.geometry} material={materials.Dirt} position={[1.288, 0.933, 2.593]} rotation={[-Math.PI, 0, 0]} scale={[-71.559, -92.934, -80.295]} />
        <mesh name="Skeleton1" geometry={nodes.Skeleton1.geometry} material={materials.BoneMat} position={[5.896, 1.945, 12.04]} />
        <mesh name="Sword10" geometry={nodes.Sword10.geometry} material={materials['Material.010']} position={[-6.708, 1.301, -0.341]} scale={[0.182, 0.064, 2.397]} />
      </group>
    </group>
  )
}

useGLTF.preload('/dungeon1-transformed.glb')
