import React, { useMemo, useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { InstancedRigidBodies } from '@react-three/rapier'

const COUNT = 20

export function Skelly(props) {
  const ref = useRef(null)

  const instances = useMemo(() => {
    const instances = []

    for (let i = 0; i < COUNT; i++) {
      instances.push({
        key: 'instance_' + Math.random(),
        position: [-2 + Math.random() * 4, 0.2 + Math.random() * 2, 0.2 + Math.random() * 2],
        rotation: [Math.random(), Math.random(), Math.random()]
      })
    }

    return instances
  }, [])

  const { nodes, materials } = useGLTF('/skeleton1-transformed.glb')
  return (
    <InstancedRigidBodies restitution={1.6} ref={ref} instances={instances} colliders="hull">
      <instancedMesh
        castShadow
        receiveShadow
        args={[undefined, undefined, COUNT]}
        count={COUNT}        
        material={materials.SkeletonMaterial}
        geometry={nodes.low_poly_skeleton.geometry}>
      </instancedMesh>
    </InstancedRigidBodies>
  )
}

useGLTF.preload('/skeleton1-transformed.glb')
