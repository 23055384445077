import React, { useState, useEffect, useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { useGLTF, useAnimations, useTexture } from '@react-three/drei'
import { GUI } from 'lil-gui'

export function Woman(props) {
  const ref2 = useRef()

  //const halo = useRef()
  // Fetch model and a separate texture
  const texture = useTexture('/stacy.jpg')
  const { nodes, animations } = useGLTF('/stacy.glb')
  // Extract animation actions
  const { ref, actions, names } = useAnimations(animations)
  // Hover and animation-index states
  const [hovered, setHovered] = useState(false)
  const [index, setIndex] = useState(4)

  useEffect(() => {
    const gui = new GUI()
    gui.add(ref.current.rotation, 'x', 0, Math.PI * 2)
    gui.add(ref.current.rotation, 'y', 0, Math.PI * 2)
    gui.add(ref.current.rotation, 'z', 0, Math.PI * 2)
    return () => {
      gui.destroy()
    }
  }, [])

  // Change cursor on hover-state
  useEffect(() => void (document.body.style.cursor = hovered ? 'pointer' : 'auto'), [hovered])

  // Change animation when the index changes
  useEffect(() => {
    // Reset and fade in animation after an index has been changed
    actions[names[index]].reset().fadeIn(0.5).play()
    // In the clean-up phase, fade it out
    return () => actions[names[index]].fadeOut(0.5)
  }, [index, actions, names])

  useFrame((state, delta) => {
    // Animate the selection halo
  })

  return (
    <group ref={ref} {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <primitive object={nodes.mixamorigHips} />
        <skinnedMesh
          castShadow
          receiveShadow
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          onClick={() => setIndex((index + 1) % names.length)}
          geometry={nodes.stacy.geometry}
          skeleton={nodes.stacy.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}>
          <meshStandardMaterial map={texture} map-flipY={false} skinning />
        </skinnedMesh>
      </group>
    </group>
  )
}
