import React, { useState, useEffect } from 'react'
import { Text } from '@react-three/drei'
import axios from 'axios'

export function AItext() {
  const [loading, setLoading] = useState(false)
  const [posts, setPosts] = useState([])
  let responded = false

  useEffect(() => {
    const loadPost = async () => {
      // Till the data is fetch using API
      // the Loading page will show.
      setLoading(true)

      if (responded === false) {
        responded = true

        const headers = {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjJhYzFmYmY0LTFhZGQtNGFiYi04ZDk5LWJlZmQxODI2MWI1ZCJ9.dqPCl7bQRYga8sOweVLVdf86SaoGvrf-m_4ZpDA0xNQ'
        }
        const data = {
          model: 'birb-nu-7b',
          messages: [
            {
              role: 'user',
              content:
                "Briefly describe an ancient medieval fantasy tomb, and nothing else. Don't ask any questions. English letters and characters only. Don't call it an ancient fantasy tomb."
            }
          ]
        }

        const response2 = await axios.post('https://api.wattak.uk/api/chat/completions', data, {
          headers: headers
        })

        setPosts(response2.data.choices[0].message.content)
      }

      // Closed the loading page
      setLoading(false)
    }

    // Call the function
    loadPost()
  }, [])

  let newString = ''
  let onlyLettersArray = []
  if (posts.length > 0) {
    newString = JSON.stringify(posts)
    onlyLettersArray = newString.split('').filter((char) => /[a-zA-Z ,--;.!]/.test(char))
  }

  return (
    <>
      {loading ? (
        <Text position={[1, 3, 1]} maxWidth="3.5" color="white" anchorX="75%" anchorY="top" fontSize="0.15">
          Thinking...
        </Text>
      ) : (
        <Text position={[1, 1, 1]} maxWidth="3.5" color="white" anchorX="75%" anchorY="top" fontSize="0.15" characters="abcdefghijklmnopqrstuvwxyz0123456789.!">
          {onlyLettersArray}
        </Text>
      )}
    </>
  )
}
