import React, { Suspense, useState, useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import { CameraControls, Html, useProgress, Stats, PerformanceMonitor } from '@react-three/drei'
import { Physics, CuboidCollider } from '@react-three/rapier'
import { Dungeon } from './Dungeon.js'
import { Woman } from './Woman.js'
import { Skelly } from './Skelly.js'
import { AItext } from './AItext.js'

export default function App() {
  const [pixelRatio, setPixelRatio] = useState(1)
  const [dpr, setDpr] = useState(1.5)

  useEffect(() => {
    setDpr([0.5, 1.0]) // Set DPR to 1 when not high-DPI and 1.5 when high-DPI
  }, [setDpr])

  function Loader() {
    const { progress } = useProgress()
    return <Html center>{Math.trunc(progress)} % loaded</Html>
  }

  return (
    <Canvas shadows dpr={pixelRatio}>
      <PerformanceMonitor
        onIncline={() => setDpr(2)}
        onDecline={() => setDpr(0.5)}
        bounds={(refreshrate) => [50, 90]}
        flipflops={Infinity}
        factor={0.5}
        step={0.1}>
        <Suspense fallback={<Loader />}>
          <Physics>
            <fog attach="fog" args={['black', 10, 30]} />
            <ambientLight intensity={0.5 * Math.PI / 4} />
            <spotLight color={0xffdd99} position={[10, 10, 10]} angle={0.3} decay={1} power={100} penumbra={1} intensity={(4 * Math.PI) / 2} />
            <pointLight castShadow color={0xff8844} position={[0, 0, 2]} decay={0.5} power={10} intensity={12 * (Math.PI / 4)} />
            <Dungeon position={[0, -2, 0]} rotation={[0, Math.PI / 2, 0]} />
            <Skelly />
            <Woman cashShadow receiveShadow position={[0.7, -1, 2]} />
            <CuboidCollider position={[0, -2, 0]} args={[20, 1, 20]} />
            <CuboidCollider position={[0, 5, 0]} args={[20, 1, 20]} />
            <CuboidCollider position={[-5, 0, 0]} args={[1, 20, 20]} />
            <CuboidCollider position={[5, 0, 0]} args={[1, 20, 20]} />
            <CuboidCollider position={[0, 0, 5]} args={[20, 20, 1]} />
            <CuboidCollider position={[0, 0, -5]} args={[20, 20, 1]} />
            <AItext />
            <CameraControls />
            <Stats showPanel={0} className="stats" />
          </Physics>
          {/* <Environment files="/moonless_golf_1k.hdr" ground={{ height: 45, radius: 100, scale: 300 }} /> */}
        </Suspense>
      </PerformanceMonitor>
    </Canvas>
  )
}
